import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber } = pageContext

  return (
    <nav aria-label="pagination" className="my-5">
      <ul className="pagination pagination-pill justify-content-center">
        <li className={"page-item " + (previousPagePath ? '' : 'disabled')}>
          {previousPagePath
            ? <Link className="page-link" to={previousPagePath}>Anterior</Link>
            : <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">Anterior</a>
          }
        </li>
          
        <li className={"page-item " + (previousPagePath ? '' : 'disabled')}>
          {previousPagePath
            ? <Link className="page-link" to={previousPagePath}>{humanPageNumber-1}</Link>
            : <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">{humanPageNumber-1}</a>
          }
        </li>
          
        <li className="page-item active" aria-current="page">
            <a className="page-link" href="#!">{humanPageNumber} <span className="sr-only">(current)</span></a>
        </li>
          
        <li className={"page-item " + (nextPagePath ? '' : 'disabled')}>
          {nextPagePath
            ? <Link className="page-link" to={nextPagePath}>{humanPageNumber + 1}</Link>
            : <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">{humanPageNumber + 1}</a>
          }
        </li>
          
        <li className={"page-item " + (nextPagePath ? '' : 'disabled')}>
          {nextPagePath
            ? <Link className="page-link" to={nextPagePath}>Siguiente</Link>
            : <a className="page-link" href="#!" tabIndex="-1" aria-disabled="true">Siguiente</a>
          }
        </li>

      </ul>
    </nav>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired
}

export default Pager