import * as React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({links}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb fs--14 mb-3">
        {links && links.map(link =>{
          return (
            <li key={link.name} className="breadcrumb-item"><Link to={link.link}>{link.name}</Link></li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs